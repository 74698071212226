import axios from "axios";
import { PredictionResult } from "./DropZone";
import { auth } from "./firebase";
import { signInAnonymously } from "firebase/auth";

const isDev = process.env.NODE_ENV === "development";

const endpoint = () => {
  if (isDev) {
    return "http://localhost:8888/car"
  } else {
    return "https://mudcar.bitapp.hk/car";
  }
}
axios.interceptors.request.use(async config => {
  const token = await getToken();
  config.headers['Authorization'] = 'Bearer ' + token;
  config.timeout = 20000;
  return (config);
}, (error) => {
  console.log(error);
  return Promise.reject(error);
});

export const getPredictions = async (formData: FormData, isUseYolo: boolean): Promise<PredictionResult> => {
  try {
    const response = await axios.post(
      endpoint() + '/inference/images?carModel=true&yolo=' + (!isUseYolo ? 'false' : 'true'),
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    console.log(response);
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
}

export const submitFeedback = (jobId: string,
  correctCarModel: string): Promise<void> => (
  axios.post(endpoint() + `/inference/${jobId}/feedback`, {
    correctCarModel: correctCarModel,
  }).then(
    () => {
      return Promise.resolve();
    },
    () => {
      return Promise.reject();
    },
  )
);

export const getModelList = async (): Promise<string[]> => {
  return axios.get(endpoint() + '/models').then(res => {
    let data = res.data;
    return data;
  });
};

export const getToken = () => {
  console.log("getoken");
  return new Promise(async (resolve, reject) => {
    try {
      const currentUser = auth.currentUser;
      if (currentUser !== null) {
        const idToken = await currentUser.getIdToken(true);
        resolve(idToken);
      } else {
        firebaseSignInAnonymously();
      }
    } catch (error) {
      console.log("get token error");
      reject(error);
    }
  });
}

export const firebaseSignInAnonymously = async () => {
  try {
    await signInAnonymously(auth);
    console.log(auth.currentUser);
    await getToken();
  } catch (e) {
    if (e instanceof Error) {
      switch (e.message) {
        case 'auth/operation-not-allowed':
          console.log('Enable anonymous in your firebase console.');
          break;
      default:
        console.error(e);
        break;
      }
    } else {
      console.error(e);
    }
  }
}