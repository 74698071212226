// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA0mcvxJtB-f8J2XmM4VgJr_GlVb44c-O0",
  authDomain: "mudcarapp.firebaseapp.com",
  databaseURL: "https://mudcarapp.firebaseio.com",
  projectId: "mudcarapp",
  storageBucket: "mudcarapp.appspot.com",
  messagingSenderId: "651109619733",
  appId: "1:651109619733:web:0f5543e69d3bc4e7e6561c",
  measurementId: "G-MFSC0MS26Q"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);