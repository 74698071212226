import React, {Component} from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import {Link} from 'react-router-dom';
import {getModelList} from "./api";
import {CircularProgress} from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";

import './ModelList.css';
import HttpErrorDialog from "./HttpErrorDialog";

interface State {
  modelList: string[],
  isLoading: boolean,
  searchString: string,
  networkError: boolean,
}

class ModelList extends Component<{}, State> {
  state = {
    modelList: [],
    isLoading: false,
    searchString: "",
    networkError: false
  };

  tryAgain = () => {
    this.getModelList();
  };

  componentDidMount(): void {
    this.getModelList();
  }

  getModelList = () => {
    this.setState({isLoading: true, networkError: false});
    getModelList().then((data) => {
      this.setState({modelList: data, isLoading: false});
    }, () => {
      this.setState({isLoading: false, networkError: true, modelList: []});
    })
  };

  searchInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let searchString = e.currentTarget.value;
    this.setState({searchString: searchString});
  };

  clearSearchString = () => {
    this.setState({searchString: ""});
  };

  _renderListItem() {
    if (this.state.networkError) {
      return "";
    }
    if (this.state.isLoading === false) {
      let modelList = this.state.modelList;
      if (this.state.searchString.length > 0) {
        modelList = this.state.modelList.filter((x: string) => x.toUpperCase().includes(this.state.searchString.toUpperCase()));
      }
      if (modelList.length > 0) {
        let listItems: JSX.Element[] = [];
        modelList.map(model => {
          listItems.push(
            <ListItem>
              <ListItemText primary={model}/>
            </ListItem>
          );
          listItems.push(<Divider/>);
        });
        return listItems;
      } else {
        return <ListItem><ListItemText style={{textAlign: "center"}} primary="No matching results"/></ListItem>;
      }
    } else {
      return <CircularProgress/>;
    }
  }

  render() {
    return (
      <div className="GridPage">
        <AppBar position="sticky">
          <Toolbar>
            <Link to="/settings">
              <IconButton color="inherit" aria-label="Close">
                <ArrowBackIos/>
              </IconButton>
            </Link>
            <Typography variant="h6" color="inherit">
              Models
            </Typography>
            <div style={{flexGrow: 1}}/>
          </Toolbar>
          <div className="SearchBar">
            <IconButton className="SearchIcon">
              <SearchIcon/>
            </IconButton>
            <InputBase classes={{root: "InputBaseRoot", input: "InputBaseInput"}} placeholder="Search..."
                       onChange={this.searchInputOnChange} value={this.state.searchString}/>
            {this.state.searchString != "" &&
            <IconButton color="inherit" aria-label="Close" className="ClearIcon" onClick={this.clearSearchString}>
              <ClearIcon/>
            </IconButton>}
          </div>
        </AppBar>
        <List className="ModelList">
          {this._renderListItem()}
        </List>
        {this.state.modelList.length > 0 &&
        <AppBar position="fixed" color="primary" className="BottomAppBar">
          <Toolbar className="BottomAppBarToolBar">
            {this.state.modelList.length + " models"}
          </Toolbar>
        </AppBar>
        }
        {this.state.networkError ? <HttpErrorDialog tryAgain={this.tryAgain}/> : ""}
      </div>
    )
  }
}

export default ModelList;
