import React, {Component} from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
import {PredictionResult} from "./DropZone";
import {CircularProgress} from "@material-ui/core";

import './FeedbackDialog.css';

import {submitFeedback} from "./api";
import Divider from "@material-ui/core/Divider";

interface State {
  open: boolean,
  submitting: boolean,
}

interface Prop {
  predictionResult: PredictionResult,

  feedbackDialogOnClose(submitted: boolean): void
}

class FeedbackDialog extends Component<Prop, State> {
  state = {
    open: true,
    submitting: false,
    submitted: false
  };

  handleClose = () => {
    this.setState({open: false});
    this.props.feedbackDialogOnClose(false);
  };

  submitFeedback(model: string) {
    let jobId = this.props.predictionResult.jobId;
    this.setState({submitting: true});
    submitFeedback(jobId, model).then(() => {
      this.setState({submitting: false});
      this.setState({open: false});
      this.props.feedbackDialogOnClose(true);
    }, () => {
      this.setState({open: false});
    });
  }

  _renderListItem() {
    if (this.props.predictionResult.carModels) {
      let listItems: JSX.Element[] = [];
      this.props.predictionResult.carModels.map(result => {
        let confidence = (result.confidence * 100);
        listItems.push(<ListItem button onClick={() => this.submitFeedback(result.class)} key={result.class}
                                 disabled={this.state.submitting}>
          <ListItemText primary={result.class}
                        secondary={confidence < 0.01 ? "< 0.01 %" : confidence.toFixed(2) + " %"}/>
        </ListItem>);
        listItems.push(<Divider/>);
      })
      return listItems;
    } else {
      return null;
    }
  }

  render() {
    return (
      <Dialog fullWidth={true} onClose={this.handleClose} open={this.state.open} aria-labelledby="simple-dialog-title">
        <div>
          <List>
            {this._renderListItem()}
            <ListItem button onClick={() => this.submitFeedback('other')} disabled={this.state.submitting}>
              <ListItemText primary="Other"/>
            </ListItem>
          </List>
          {this.state.submitting && <CircularProgress size={24} className="CircularProgress"/>}
        </div>
      </Dialog>
    );
  }
}

export default FeedbackDialog;